/* --- MAJ 11/02/2021 --- */

.menu_admin {
  max-width: 350px;

  .admin_ariane {
    position: relative;
    top: -2rem;
    display: none;
    color: $color-1;
    fill: $color-1;
    margin-bottom: 1rem;
    text-align: left;
  }

  ul {
    li {
      border-bottom: 1px solid $gray-3;
      margin-bottom: 0.4rem;

      &.menu_admin_active a {
        color: $color-1;
      }

      path {
        fill: $color-1;
      }
    }

    a,
    .menu_admin_item_shop {
      position: relative;
      display: block;
      cursor: pointer;
      text-transform: uppercase;
      font-size: 1.25rem;
      padding: 0.4rem 0 0.8rem 0;
      font-weight: 500;
      transition: all 150ms;
    }

    .menu_admin_child {
      height: 0;
      opacity: 0;
    }

    .menu_admin_child_active {
      height: auto;
      opacity: 1;
    }

    svg {
      margin-right: 0.7rem;
      width: 35px;
      height: auto;
      vertical-align: sub;
    }

    .menu_admin_arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .menu_admin_child {
    margin-left: 2rem;
    li {
      border: none;
    }
    a {
      font-size: 1rem;
      padding: 0.2rem 0;
    }
    svg {
      width: 30px;
    }
  }

  .webmaster_contact {
    margin-top: 2rem;
    padding: 1rem;
    background-color: $gray-2;
    color: $color-dark;

    .webmaster_contact_title {
      display: block;
      border-bottom: 2px solid $color-dark;
      color: $color-1;

      padding-bottom: 0.5rem;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.3rem;
      margin-bottom: 1.5rem;
    }

    .webmaster_contact_pm {
      font-size: 1.4rem;
      line-height: 2rem;

      a {
        color: $color-dark;
        transition: all 150ms;
        &:hover {
          color: $color-1;
        }
      }
    }

    .webmaster_contact_urgence {
      display: none;
      margin-top: 1.5rem;
      font-size: 0.85rem;
      color: $color-dark;
      font-weight: 500;

      a {
        color: $color-dark;
        transition: all 150ms;
        &:hover {
          color: $color-1;
        }
      }
    }
  }
}

/* ------------------------------------------------------------- RESPONSIVE ------------------------------------------------------------- */

@media screen and (max-width: 1000px) {
  // ARIANE VISIBLE
  .menu_admin {
    .admin_ariane {
      display: block;
    }
    //MENU INVISIBLE SUR LES PAGES
    ul,
    .webmaster_contact {
      display: none;
    }
  }

  // MENU VISIBLE SUR DASHBOARD
  .dashboard-admin {
    .menu_admin {
      ul,
      .webmaster_contact {
        display: block;
      }
    }
  }
  .espace-admin {
    div.content_container {
      display: block;
      text-align: left;
      .menu_admin {
        display: flex;
        max-width: 100%;
        min-width: initial;
        text-align: center;

        li {
          display: inline-block;
          border: 0;
          margin: 0 1rem;
          svg {
            display: block;
            margin: 0 auto 0.5rem auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .dashboard-admin {
    div.content_container {
      display: block;
      .menu_admin {
        li {
          display: block;
          border-bottom: 1px solid $gray-3;
          margin: 1rem 0;
          svg {
            display: inline-block;
            margin: 0 0.5rem 0 0;
          }
        }
      }
    }
  }
}
