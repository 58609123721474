.home-hero {
  position: relative;
  background-color: $gray-2;
  padding-top: 2rem;

  .swiper-wrapper {
    width: 100%;
    // height: 550px;
    // margin-bottom: rem(30);
    padding-bottom: 10rem;
  }

  .swiper__slide {
    position: relative;
    width: 100%;
  }

  .swiper-slide {
    width: 310px;
  }

  .card {
    position: relative;
  }

  .card__title {
    position: absolute;
    text-transform: uppercase;
    font-size: rem(16);
    font-weight: 500;
    z-index: 1;
    top: 5px;
    right: 5px;
    padding: 0 5px;
    color: white;
    background-color: $color-1;
  }

  .swiper-slide-active {
    margin: 0 30px 0 15px !important;

    img {
      transform: scale(1.1);
      transition: all 0.2s;
    }
  }

  // ------------------------------------------------------------- NAVIGATION
  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    // font-weight: bold;
    // transform: scale(1.1);
    top: initial;
    bottom: 2rem !important;
    color: white;
    background-color: $color-1;
    width: 60px;
    height: 60px;
    // margin: 0 25px;

    &::after {
      font-size: 2rem !important;
    }
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: calc(50% - 80px);
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: calc(50% - 80px);
  }

  // ------------------------------------------------------------- PAGINATION

  .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: centr;
    // bottom: calc(30px - 8px);
  }

  .swiper-pagination-bullet {
    margin: 0 rem(10) !important;
    width: 16px;
    height: 16px;
    background-color: white;
    opacity: 0.6;
    // box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.5);

    &.swiper-pagination-bullet-active {
      width: 20px;
      height: 20px;
      background-color: $color-1;
      opacity: 1;
    }
  }
}

//------------------------------------------------------ RESPONSIVE
@include breakpoint(800) {
  .home-hero {
    .swiper-slide {
      width: 280px;
    }
    .swiper-slide-active {
      margin: 0 15px 0 0 !important;

      img {
        transform: scale(1);
        transition: all 0.2s;
      }
    }
    .swiper-wrapper {
      width: 100%;
      // height: 550px;
      // margin-bottom: rem(30);
      padding-bottom: 5rem;
    }

    // ------------------------------------------------------------- NAVIGATION
    .swiper-button-prev,
    .swiper-button-next {
      bottom: 2rem !important;
      width: 40px;
      height: 40px;
      // margin: 0 25px;

      &::after {
        font-size: 1.5rem !important;
      }
    }
    .swiper-button-prev,
    .swiper-container-rtl .swiper-button-next {
      left: calc(50% - 50px);
    }
    .swiper-button-next,
    .swiper-container-rtl .swiper-button-prev {
      right: calc(50% - 50px);
    }
  }
}
