.admin_actu {
  input#fichier {
    height: auto !important;
    padding: 0.5rem 0;
  }

  /* --------------------------------- EDITION / MODIFICATION --------------------------------- */
  .mceBranding,
  .mceStatusbar,
  #editor-xtd-buttons {
    display: none !important;
  }

  /* --------------- ETAT --------------- */
  .art_state {
    position: relative;
    margin: 0 2rem;
    a {
      display: flex;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
    }
    .art_state_active {
      display: block;
    }
  }

  /* --------------------------------- eo EDITION / MODIFICATION --------------------------------- */

  /* --------------------------------- LISTE CATEGORIES --------------------------------- */

  .actu_admin_content {
    /* --------- SIZE --------- */
    .actu_admin_item {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
    }

    .actu_admin_item {
      position: relative;
      padding: 1rem 1rem 2rem 1rem;
      border-bottom: 1px solid $gray-3;

      &:nth-child(even) {
        background-color: $gray-2;
      }

      .item_title_infos {
        display: block;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 500;
        color: $color-1;
      }
    }

    .item_title {
      flex: 0 0 450px;
      text-align: left;
      font-weight: 500;
    }

    .item_category {
      position: absolute;
      left: 1rem;
      bottom: 8px;
      background-color: $color-dark;
      color: $color-light;
      text-transform: uppercase;
      font-size: 0.7rem;
      padding: 2px 6px;
      border-radius: 50px;
    }

    .item_author {
      padding: 0 1rem;
    }

    .item_clic {
      padding: 0 1rem;
    }

    .item_modify,
    .item_delete {
      padding: 3px 7px;
      border-radius: 50px;
      line-height: initial;
      margin: 0;
      text-transform: uppercase;
      font-size: 0.7rem;
    }
  }
  /* --------------------------------- eo LISTE CATEGORIES --------------------------------- */
}
