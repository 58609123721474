.login__forget {
  width: 600px;

  .btn {
    margin-top: 1rem;
    width: 100%;
  }

  .forget__infos {
    // font-size: rem(18);
    text-align: center;
    // color: $gray-8;
    font-weight: 400;
  }
}
