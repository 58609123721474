@charset 'utf-8';

@import "utils/functions";
@import "utils/mixins";
@import "utils/variables";
@import "utils/scrollbar";

@import "base/fonts";
@import "base/reset";
@import "base/responsive";
@import "base/typography";

@import "components/buttons";
@import "components/form";
@import "components/pagination";
@import "components/modal";
@import "components/navigation";
@import "components/message";
@import "components/breadcrumb";
@import "components/card";
@import "components/slide-all";

@import "layout/footer";
@import "layout/grid";
@import "layout/header";

@import "pages/404";
@import "pages/sitemap";
@import "pages/rgpd";
@import "pages/home-hero";
@import "pages/home-services";
@import "pages/home-a-propos";
@import "pages/home-assistance";
@import "pages/home-produits";
@import "pages/logiciels-offres";
@import "pages/formations";
@import "pages/materiel";
@import "pages/contact";
@import "pages/categories";

@import "administration/template";

@import "vendors/swiper-bundle.scss";

@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
// ---------------------------------------------- FONTAWESOME
// @import "vendors/fontawesome/fontawesome";
// @import "vendors/fontawesome/solid";
// @import "vendors/fontawesome/regular";
