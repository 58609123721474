.header {
  padding: 1rem rem(110);
  background-color: white;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// ------------------------ Logo
.header__logo {
  font-size: rem(50);
  a {
    display: flex;
  }
}

//------------------------------------------------------ RESPONSIVE
@include breakpoint(1600) {
  .header {
    padding: 1rem rem(40);
  }
}

@include breakpoint(550) {
  .header {
    padding: rem(10) rem(20);
  }
  .header__logo svg {
    max-height: 75px;
    width: auto;
  }
}
