.hikashop_products_listing {
  .hk-row-fluid {
    justify-content: center;
  }

  .hikashop_product {
    width: 225px;
    margin: 0 1rem;
  }

  .hikashop_listing_img_title {
    border: 1px solid $gray-4;
    padding: 2rem 1rem;
    width: 225px;
    background-color: white;

    .hikashop_product_name {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
      font-size: rem(15);
      font-weight: 500;
    }

    .btn {
      font-size: rem(16);
      text-transform: lowercase;
    }
  }
}
