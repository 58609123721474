%form {
  display: block;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
  width: 100%;

  //----------------------------- STYLE
  font-family: $font-normal;
  background-color: white;
  box-shadow: none;
  font-weight: 600;
  color: $color-dark;
  border: 1px solid $gray-4;
  // border-radius: 4px;
  font-size: rem(16);
  padding: 0 rem(50) 0 rem(16);
  // padding: 20px 20px 0 20px;
  transition: box-shadow 200ms ease;

  &:hover {
    // background-color: rgba($color: $color-2, $alpha: 0.05);
    border: 1px solid $color-2;
  }

  &:focus {
    border: 1px solid $color-2;
    background-color: rgba($color: $color-2, $alpha: 0.05);
  }
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-background-clip: text;
  font-size: 1.2rem;
}

input:-webkit-autofill::first-line {
  font-size: rem(20);
}

// ------------------------------------------------ checkbox hikashop
.hikashop_filter_checkbox {
  display: inline-flex;
  align-items: center;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  background-color: white;
  display: inline-flex;
  vertical-align: middle;
  border: 0;
  padding: 0;
  box-shadow: none;
  width: 25px;
  height: 25px;
  border-radius: 2px;

  &:checked {
    &::after {
      content: "\f00c";
      font-family: "Font Awesome\ 5 Free";
      position: absolute;
      line-height: 25px;
      text-align: center;
      width: 100%;
      font-size: 1.2rem;
      color: $color-2;
    }
  }
  &:hover {
    border: 0;
  }
  &:focus {
    border: 0;
    background-color: white;
  }
}

.form-group,
.control-group {
  position: relative;
  margin-bottom: $mg-1;
}

fieldset {
  border: 0;
}

.btn--submit {
  width: 100%;
}

label {
  position: relative;
  display: block;
  margin-left: rem(10);
  text-transform: uppercase;
  // pointer-events: none;
  font-size: rem(15);
  // color: $gray-8;
  font-weight: 300;
}

input {
  position: relative;
  @extend %form;
  height: rem(60);
  line-height: 1.2;

  &:hover {
    // background-color: rgba($color: $color-2, $alpha: 0.05);
    border: 1px solid $color-2;
  }

  &:focus {
    border: 1px solid $color-2;
    background-color: rgba($color: $color-2, $alpha: 0.05);
  }
}
// --------------------------- SELECT - supprimer arrow sur ie
select::-ms-expand {
  display: none;
}
select {
  @extend %form;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: rem(60);
  line-height: 1.2;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMXJlbSIgd2lkdGg9IjFyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAxNiAxNiI+PHBhdGggZD0iTTggMTEuNWExIDEgMCAwMS0uNzEtLjI5bC01LTVhMSAxIDAgMDExLjQyLTEuNDJMOCA5LjA5bDQuMjktNC4zYTEgMSAwIDExMS40MiAxLjQybC01IDVhMSAxIDAgMDEtLjcxLjI5eiIvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 1rem;
  background-position: right 1rem center;
}

textarea {
  @extend %form;
  line-height: 1.375;
  max-width: 100%;
  max-height: 200px;
}

::-webkit-input-placeholder,
::-moz-placeholder,
::-ms-input-placeholder {
  font-size: rem(14);
}

/* --------------------------------------------------------- A REFAIRE --------------------------------------------------------- */

/* ---------------------------------------- FOX CONTACT ---------------------------------------- */

.fox-container {
  text-align: center;
  background: $gray-1;
  padding: 2rem 0 6rem 0;

  .fox-form {
    width: 65rem;
    margin: 2rem auto 0 auto;
  }

  h2 {
    margin-bottom: 3rem;
  }
}

.required::after,
.asterisk::after {
  content: none !important;
}

.fox-item-html {
  margin-bottom: 2rem !important;
}

.fox-item {
  .controls {
    float: none !important;
  }

  input {
    line-height: 50px !important;
    height: 50px !important;
    padding-left: 15px !important;
    width: 100% !important;
  }

  textarea {
    height: 288px !important;
    padding-left: 15px !important;
    width: 100% !important;
    padding-top: 0.7rem !important;
  }

  &.fox-item-submit-fields .controls {
    width: 100% !important;
  }
}

.fox-item-submit {
  position: relative;

  .controls {
    text-align: left !important;
  }
}

.fox-item .btn {
  margin-top: 1rem;
  width: 100%;
}

/* ======================================================================  RESPONSIVE ============================= */

@media screen and (max-width: 1250px) {
  .fox-container {
    width: 100%;
    padding: 3rem 0 5rem 0;

    .fox-form {
      width: 95%;
    }

    h2 {
      margin-bottom: 3rem;
    }

    .fox-item {
      .controls {
        text-align: center !important;
      }
    }

    .fox-column {
      float: none !important;
      width: 100% !important;
      margin-left: 0 !important;
    }

    .fox-item input,
    .fox-item-dropdown-select-single,
    .fox-item-text-area textarea,
    .fox-item-recaptcha .controls,
    .fox-item-submit .controls {
      width: 100% !important;
    }

    .fox-item-recaptcha,
    .fox-item-submit {
      margin: 25px 0 !important;
    }

    .fox-form,
    .fox-form p {
      padding: 7px 0.5rem;
    }
  }
}
