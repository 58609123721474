// --------------- LAST

.espace-client .main {
  max-width: 1000px;
  padding: 0 1rem;
  margin: auto;
}

.espace-client__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 5px solid $color-1;
  padding-bottom: 1rem;

  .header__title {
    font-size: 2rem;
    font-weight: bold;
  }

  #login-form {
    display: flex;
    align-items: center;
  }

  .login-greeting {
    margin-right: 1rem;
  }
}

#hikamarket_cpanel_title,
#hikamarketcpanel h2 {
  display: none !important;
}

#hikashop_dashboard {
  padding: 2rem 0;
  text-align: center;
}

#hikamarket_products_form {
  padding: 3rem 0;
}
/* ------------------- 15/02/2021 ------------------- */

.dashboard {
  flex: 1;
  margin-left: 2rem;
  .dashboard_content {
    width: 100%;
  }
  .dashboard_wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  /* ----------------------------------------- BOUTIQUE -------------------------------------- */
  .dashboard_shop {
    display: flex;
    flex-flow: row wrap;

    .dashboard_shop_items:nth-child(1) {
      flex: 1 0 500px;
      min-width: 500px;
      margin-right: 1rem;
    }

    .dashboard_shop_items:nth-child(2) {
      flex: 1 0 200px;
      min-width: 200px;
      text-align: center;
    }

    .dashboard_shop_items:nth-child(3) {
      flex: 1 0 100%;
      min-width: 100%;
      //text-align: center;
    }

    .dashboard_shop_item {
      border: 1px solid $gray-3;
    }

    /* --------------------------- GRAPH --------------------------- */
    .dashboard_item_shop_graph {
      height: 400px;
      .dashboard_item_title {
        padding: 0.5rem 0 0.5rem 1.5rem;
      }
    }

    /* --------------------------- TITLE --------------------------- */
    .dashboard_item_title {
      display: block;
      background-color: $gray-3;
      color: $color-1;
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 0.5rem 0 0.5rem 0;
    }

    /* --------------------------- RESULT --------------------------- */
    .dashboard_item_result {
      display: block;
      font-size: 1.3rem;
      font-weight: 500;
      padding: 0.5rem 0;
    }

    /* --------------------------- LES DERNIERE COMMANDES --------------------------- */
    .dashboard_item_shop_last_order {
      table {
        margin: auto;
        thead th {
          padding-bottom: 0.5rem;
        }
        th {
          font-size: 0.9rem;
        }
        th,
        td {
          padding: 0 4px;
        }
        th:nth-child(1),
        td:nth-child(1) {
          text-align: left;
        }
        th:nth-child(2),
        td:nth-child(2) {
          border-style: solid;
          border-color: $gray-3;
          border-width: 0 1px 0 1px;
        }
        th:nth-child(3),
        td:nth-child(3) {
          text-align: right;
        }
      }
    }

    /* --------------------------- COMMANDES DU MOIS --------------------------- */
    .dashboard_item_shop_month_order {
      margin-top: 1.15rem;
    }

    /* --------------------------- SHOP LINKS --------------------------- */
    .dashboard_shop_menu {
      display: flex;
      flex-flow: row wrap;
      margin-top: 1rem;

      path {
        fill: $color-1;
      }

      .dashboard_shop_link {
        flex: 1 0 100%;
        border: 1px solid $gray-3;
        margin-right: 1rem;

        text-align: center;
        text-transform: uppercase;
        font-size: 0.8rem;

        &:last-child {
          margin-right: 0;
        }

        a {
          display: block;
          padding: 1rem;
        }

        svg {
          display: block;
          margin: 0 auto 1rem auto;
          width: auto;
          height: 35px;
        }
      }
    }
  }

  /* ----------------------------------------- FONCTIONNALITES -------------------------------------- */
  .dashboard_app {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    //align-items:center;

    .dashboard_app_item {
      text-align: center;
      flex: 0 0 290px;
      border: 1px solid $gray-5;
      margin: 0 1rem 1rem 0;
      a {
        display: block;
        padding: 2rem 0;
      }

      svg {
        //display: block;
        margin: 0 auto 2rem auto;
        height: 75px;
        width: auto;
        path {
          fill: $color-1;
        }
      }

      span {
        display: block;
        margin-bottom: 1rem;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.25rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.6rem;
        padding: 0 2rem;
      }
    }
  }
}

/* ------------------------------------------------------------- RESPONSIVE ------------------------------------------------------------- */

@media screen and (max-width: 1000px) {
  .dashboard {
    .dashboard_app {
      display: none;
    }
  }
}

@include breakpoint(750) {
  .espace-client__header {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }
  .header__title {
    margin: 1rem 0;
  }
  .espace-client__header #login-form {
    display: block;
    font-size: 1.2rem;
  }
}
