.home-assistance {
  .assistance__container {
    background-color: #313131;
    color: white;
    padding: $mg-5 1rem;
  }

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 270px);
    grid-auto-flow: dense;
    gap: 20px;
    justify-content: center;
  }

  .card {
    background-color: #303030;
    border: 1px solid #4d4d4d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 1rem 2rem 1rem;
  }

  h3 {
    margin: 1rem 0;
  }

  .assistance__partner {
    background-color: white;
    text-align: center;
    padding: 1rem;
  }
}

@include breakpoint(980) {
  .home-assistance {
    .assistance__container {
      background-color: #313131;
      color: white;
      padding: $mg-5 0;
    }

    .cards {
      display: grid;
      grid-template-columns: 1fr;
      margin: 0 1rem;
      gap: 20px;
      justify-content: center;
    }
  }
}
