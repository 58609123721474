.page-114 .footer {
  margin-top: 0;
}

// ------------------------ FOX CONTACT

// ------------------------ MAP
#map {
  width: 100%;
  height: 500px;
}

@include breakpoint(700) {
  #map {
    height: 300px;
  }
}
