html {
  scroll-behavior: smooth;
}

body {
  word-break: break-word;
  background-color: $color-light;
  // padding-bottom: 10rem;
}

#wrapper {
  position: relative;
  width: 100%;
  margin: auto;
  min-height: 100vh;
  overflow: hidden;
}

.container {
  margin: 0 auto;
  max-width: rem(1200);
}

.section {
  padding: rem(200) 0;
}
