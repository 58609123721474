.formations {
  padding: $mg-2 1rem $mg-4 1rem;
  text-align: center;

  .cards {
    margin: $mg-6 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-auto-flow: dense;
    justify-content: center;
    gap: 20px;
  }

  .card {
    position: relative;
    // height: 275px;
    text-align: center;
    padding: rem(60) 1rem rem(60) 1rem;
    border: 1px solid $gray-4;
  }

  .card__category {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 12px;
    font-weight: 500;
    font-size: rem(20);
    text-transform: uppercase;
    background-color: $gray-6;
    color: white;
  }

  .card__title {
    display: block;
    text-transform: uppercase;
    font-size: rem(20);
    font-weight: bold;
    margin-top: 1rem;
    // text-align: center;
  }
}

.formations__niveaux {
  position: relative;
  background-image: url("/images/formations/deco-formations.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  z-index: 1;
  padding: $mg-7 1rem;
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0;
    background-color: rgba(169, 3, 0, 0.4);
  }
  h2 {
    color: white;
  }
  p {
    max-width: 725px;
    margin: auto;
    color: white;
  }
}
