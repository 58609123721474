/* --------------- 22/02/2021 --------------- */

#editcatform {
  margin-bottom: 2rem;

  select {
    margin: 1rem 0;
    height: 50px;
    font-size: 1.1rem;
    padding: 0 1rem;
    option {
      height: 50px;
    }
  }
}

.admin_cat_items {
  .cat_item {
    position: relative;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    flex: 1 0 100%;

    &::before {
      content: "";
      position: absolute;
      top: 32px;
      border: 1px dashed $color-1;
      margin-right: 0.7rem;
    }

    &[data-level="1"] {
      &::before {
        width: 20px;
      }
    }
    &[data-level="2"] {
      &::before {
        width: 40px;
      }
    }
    &[data-level="3"] {
      &::before {
        width: 60px;
      }
    }
    &[data-level="4"] {
      &::before {
        width: 80px;
      }
    }
    &[data-level="5"] {
      &::before {
        width: 100px;
      }
    }
    &[data-level="6"] {
      &::before {
        width: 120px;
      }
    }
  }

  .cat_parent {
    padding: 1rem;
  }

  &:nth-child(even) {
    background-color: $gray-2;
  }

  /* --------------- CHILD --------------- */

  .cat_child {
    width: 100%;
    //margin: 1rem 0 0 0;
    //padding-top: 1rem;
  }

  .cat_arrow_child {
    margin-right: 1rem;
    width: 32px;
    font-weight: bold;
    color: $color-dark;
  }

  /* --------------- TITRE --------------- */
  .cat_title {
    flex: 0 0 450px;
    padding-left: 80px;
    text-align: left;
    font-weight: 500;
    max-width: 450px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 33px;
      width: 100px;
      left: 0px;
      border-bottom: 1px solid $gray-3;
    }
  }

  /* --------------- ETAT --------------- */
  .cat_state {
    position: relative;
    margin: 0 2rem;
    a {
      display: flex;
    }
    svg {
      display: none;
    }
    .cat_state_active {
      display: block;
    }
  }

  /* --------------- ORGANISER --------------- */
  .cat_organize {
    margin: 0 2rem;
    svg {
      cursor: pointer;
    }
    a {
      display: flex;
      &:hover {
        path {
          transition: all 150ms;
          fill: $color-1;
        }
      }
    }
    .cat_up {
      margin-bottom: 0.2rem;
    }
  }

  .btn {
    padding: 3px 7px;
    border-radius: 50px;
    line-height: initial;
    margin: 0;
    text-transform: uppercase;
    font-size: 0.7rem;
  }
}

/* -------------------------------------------------- RESPONSIVE -------------------------------------------------- */

@media screen and (max-width: 1260px) {
  /* --------------- TITRE --------------- */
  .admin_cat_items .admin_cat_item .cat_title {
    flex: 0 0 190px;
    max-width: 190px;
  }
}

@media screen and (max-width: 700px) {
  // column wrap

  .admin_cat_items .admin_cat_item .cat_title {
    flex: 1 0 200px;
    max-width: initial;
  }
}
