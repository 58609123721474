html {
  font-size: $font-size-base;
}

body {
  font-family: $font-normal;
  line-height: 1.5;
  color: $color-dark;
}

// Title
$font-size-h1: rem(45) !default;
$font-size-h2: rem(45) !default;
$font-size-h3: rem(25) !default;
$font-size-h4: rem(18) !default;
$font-size-h5: rem(16) !default;
$font-size-h6: rem(14) !default;

h1,
h2,
h3,
h4,
h5,
h6 {
  text-rendering: optimizelegibility;
}

/*
************* TITRE
* ===================================
*/

// ------------ Custom
.title__main {
  text-align: center;
  margin-bottom: $mg-4;

  .title__slogan {
    display: block;
    font-size: rem(18);
    font-weight: 300;
    color: $color-3;
    text-transform: uppercase;
  }
}
h1,
h2 {
  position: relative;
  z-index: 1;
  font-size: rem(45);
  font-weight: bold;
  text-align: center;
  margin-bottom: $mg-5;
  padding: 0 1rem;
  &::after {
    content: "";
    display: block;
    margin: auto;
    width: 100px;
    height: 6px;
    background-color: $color-1;
  }
}
// ------------ EO H2
h3 {
  font-size: $font-size-h3;
  font-family: $font-titre;
  font-weight: normal;
  line-height: 1.5;
}

h4 {
  font-size: $font-size-h4;
  line-height: 1.5;
}

h5 {
  font-size: $font-size-h5;
  line-height: 1.5;
}

h6 {
  font-size: $font-size-h6;
  line-height: 1.5;
}

/*
************* TEXTE
* ===================================
*/

p {
  font-size: rem(18);
  font-weight: 400;
  line-height: 1.7;
  font-weight: 300;
  padding-bottom: 7px;
}

a {
  text-decoration: none;
  color: inherit;

  &:hover {
    //color: $color-2;
    //transition: color 200ms ease-in-out;
  }
}

address {
  font-style: normal;
}

// ===================================== RESPONSIVE

@include breakpoint(1500) {
  h1,
  h2 {
    font-size: rem(35);
    margin-bottom: $mg-4;
    &::after {
      height: 4px;
    }
  }
}

@include breakpoint(1200) {
  h1,
  h2 {
    font-size: rem(30);
  }
}

@include breakpoint(800) {
  h1,
  h2 {
    font-size: rem(25);
    &::after {
      height: 3px;
      width: 75px;
    }
  }
}
