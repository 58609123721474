.categorie-slide {
  position: relative;
  height: 250px;

  margin-bottom: $mg-4;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0;
    background-color: rgba($color: $color-1, $alpha: 0.4);
  }

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
  }
}

.slide__title {
  position: absolute;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 500;
  font-size: rem(45);
  text-transform: uppercase;
}

@include breakpoint(1200) {
  .categorie-slide {
    height: 300px;
  }
  .slide__title {
    font-size: rem(30);
  }
}

@include breakpoint(550) {
  .categorie-slide {
    height: 200px;
  }
  .slide__title {
    font-size: rem(20);
  }
}
