.logiciel-offres {
  padding: $mg-7 1rem;

  .cards {
    display: grid;
    grid-template-columns: repeat(3, 390px);
    justify-content: center;
    gap: 10px;
  }

  .card {
    height: 350px;
    position: relative;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      height: 100%;
    }
  }

  .card__title {
    position: absolute;
    bottom: 10px;
    left: 0;
    background-color: rgba($color: white, $alpha: 0.7);
    font-size: rem(25);
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 1rem;
  }
}

@include breakpoint(1250) {
  .logiciel-offres {
    .cards {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-auto-flow: dense;
    }
  }
}
@include breakpoint(550) {
  .logiciel-offres {
    .cards {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-auto-flow: dense;
    }
    .card {
      height: 125px;
    }
    .card__title {
      font-size: rem(16);
    }
  }
}
