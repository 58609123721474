.admin_gixportfolio {
  .input_file {
    border-bottom: 1px solid gray-5;
    #fichier {
      display: block;
      border: 0;
      padding: 0 !important;
      height: auto;
    }

    ::-webkit-file-upload-button {
      background: transparent;
      color: $color-1;
      padding: 1em;
      border: 2px solid $color-1;
      margin-right: 1rem;
      cursor: pointer;
    }
  }

  .input_cat {
    margin-top: 1rem;
    label {
      display: block;
      margin-bottom: 1rem;
    }
    select {
      background: transparent;
      color: $color-1;
      padding: 1em;
      border: 2px solid $color-1;
    }
  }

  .image-submit {
    input.btn {
      width: initial;
      padding: 0 2rem !important;
      &:hover {
        background-color: $color-1 !important;
      }
    }
  }

  .container-image {
    margin: 2rem 0;
    padding-top: 2rem;
    border-top: 3px solid gray-5;
  }

  .titre-image {
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 1rem;
    color: $color-1;
  }

  .wrapper-bloc-image {
    .bloc-image {
      position: relative;
      display: inline-block;
      margin: 0 0.5rem 0.5rem 0;
      .content-image {
        overflow: hidden;
        img {
          max-height: 270px;
        }
        span {
          position: absolute;
          z-index: 1;
          width: 100%;
          left: 0;
          bottom: 1rem;
          background-color: rgba(255, 255, 255, 0.7);
          padding: 0.5rem;
          font-size: 0.9rem;
          color: $color-dark;
        }
        a {
          position: absolute;
          top: 1rem;
          right: 1rem;
          width: 30px;
          height: 30px;
          box-shadow: 0 10px 21px rgba(0, 0, 0, 3);
          //background-color: #fff;
          border-radius: 50px;

          svg {
            width: 30px;
            height: 30px;
          }
          path {
            fill: $color-1;
          }
        }
      }
    }
  }
}
