.breadcrumb--list {
  position: relative;
  z-index: 10;
  margin-top: -1.5rem !important;
  //   background-color: red;
  display: flex;
  align-items: center;

  //   ---------------------------- arrow
  .divider img {
    margin: 0 rem(5);
  }

  li {
    text-transform: lowercase;
  }

  .pathway:hover {
    color: $color-2;
  }

  .active {
    font-weight: bold;
  }
}
