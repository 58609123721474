.footer {
  position: relative;
  background-color: $gray-2;
  margin-top: $mg-4;
}

.footer__container {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  grid-auto-flow: dense;
  gap: 50px;
  align-items: stretch;
  padding: 2rem 1rem;
}

// --------------------------------------------------------- items
.footer__item {
  margin: 1rem 0;
}

// --------------------------------------------------------- title
.footer__title {
  display: block;
  font-size: rem(20);
  color: $color-1;
  font-weight: bold;
  margin-bottom: $mg-2;
}

// --------------------------------------------------------- logo
.footer__item--logo {
  p {
    margin-top: $mg-2;
    font-size: rem(15);
  }
}

// --------------------------------------------------------- navigation
.footer__item--nav {
  .menu__link {
    font-size: rem(20);
    font-weight: 300;
  }
}

// --------------------------------------------------------- contact
.footer__item--contact {
  li {
    margin: rem(10) 0;
  }
}

.contact__link {
  display: flex;
  align-items: center;

  i {
    margin-right: rem(8);
    font-size: rem(26);
    text-align: center;
    width: 30px;
    line-height: 30px;
    color: $color-1;
  }

  address,
  span {
    font-size: rem(16);
  }
}

// --------------------------------------------------------- Mentions
.footer__mentions {
  background-color: white;
  text-align: center;
  padding: rem(10) 1rem;
  p {
    padding: 0;
  }
}

// .footer__title::before {
//   display: inline-flex;
//   font-family: $font-awe;
//   content: "\f004";
// }

// ----------------------------------- Responsive

@include breakpoint(980) {
  .footer__container {
    display: block;
    text-align: center;
    padding: 2rem 1rem;
  }

  .footer__item {
    margin: 2rem 0;
  }
  .contact__link {
    display: block;
  }

  .footer__mentions p {
    font-size: rem(14);
  }
}
