.home-services {
  margin: $mg-8 auto;
  padding: 0 1rem;

  .services__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1300px;
    gap: 20px;
    justify-content: center;
    margin: auto;
  }
  .card {
    position: relative;
    background-size: cover;
    background-position: center;
    padding: 1rem 1.5rem;
    z-index: 1;
    // min-height: 280px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;

    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba($color: $color-1, $alpha: 0.6);
    }
  }

  //  ------------------------------- Background
  .card--ebp {
    background-image: url("/images/bg-logiciel-ebp.jpg");
  }
  .card--securite {
    background-image: url("/images/bg-securite.jpg");
  }
  .card--materiel {
    background-image: url("/images/bg-materiel.jpg");
  }
  .card--formations {
    background-image: url("/images/bg-formations.jpg");
  }

  .card__title {
    display: inline-block;
    color: white;
    font-weight: bold;
    font-size: rem(35);
    text-transform: uppercase;

    &::after {
      content: "";
      display: block;
      position: relative;
      width: calc(100% + 1.5rem);
      left: -1.5rem;
      height: 3px;
      background-color: white;
      margin: 0 0 1.5rem 0;
    }
  }

  p {
    font-size: rem(16);
    color: white;
    margin-bottom: 2rem;
  }

  .btn {
    text-transform: capitalize;
    color: $color-1;
    background-color: white;
    font-size: rem(16);
    padding: 7px 10px;
    &:hover {
      border-color: transparent;
      background-color: rgba($color: white, $alpha: 0.8);
    }
  }
}

// ---------------------- Responsive

@include breakpoint(800) {
  .home-services {
    .services__container {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-auto-flow: dense;
    }
  }
}
