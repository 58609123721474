.page-materiel {
  text-align: center;
  padding: $mg-4 1rem;

  .cards {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
    grid-auto-flow: dense;
    gap: 20px;
  }
  .card {
    border: 1px solid $gray-4;
    padding: 1rem;
    height: 275px;

    img {
      max-height: 155px;
    }
  }

  .card__title {
    display: block;
    font-size: rem(20);
    font-weight: 500;
    margin-top: 1rem;
    text-transform: uppercase;
  }

  p {
    padding: 2rem 1rem;
    text-transform: uppercase;
    color: $color-1;
    font-size: rem(20);
    font-weight: 500;
  }
}

@include breakpoint(550) {
  .page-materiel {
    .card {
      height: auto;
    }
  }
}
