/* --- MAJ 11/02/2021 -- */

.header_admin {
  width: 80rem;
  margin: 3rem auto 5rem auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid $gray-3;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 0;
  }

  .header_admin_right {
    display: flex;
    align-items: center;

    span {
      display: block;
      margin-right: 2rem;
    }
    .btn {
      margin-top: 0;
      a {
        color: $color-light;
      }
      &:hover a {
        color: $color-1;
        transition: all 150ms;
      }
    }
  }
}

/* ------------------------------------------------------------- RESPONSIVE ------------------------------------------------------------- */

@media screen and (max-width: 1400px) {
  .header_admin {
    width: auto;
    margin: 3rem 1rem;

    h1 {
      font-size: 2rem !important ;
      margin: 0 !important;
    }
  }
}

@media screen and (max-width: 980px) {
  .header_admin {
    display: block;
    h1 {
      text-align: center;
      margin-bottom: 2rem !important;
    }
    .header_admin_right {
      text-align: center;
      display: block;
      span {
        display: block;
        margin: 0 0 1rem 0;
      }
      .btn {
        padding: 0 1rem;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .header_admin {
    margin: 1rem;
    h1 {
      font-size: 1.7rem !important ;
      margin-bottom: 1rem !important;
    }
    .header_admin_right {
      text-align: center;
      display: block;
      span {
        font-size: 1rem;
      }
    }
  }
}
