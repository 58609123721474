.home-a-propos {
}

.apropos__first {
  background-color: $gray-2;
  padding: $mg-7 1rem;
}
.apropos__first .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 3rem;
}

.apropos__second {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 0.5fr;
  gap: 3rem;
  padding: $mg-7 1rem;

  .left div {
    margin-bottom: $mg-4;
  }

  h3 {
    margin-bottom: $mg-3;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 3px;
      background-color: $color-1;
    }
  }
}

.apropos__securite {
  display: grid;
  align-items: center;
  grid-template-columns: 0.5fr 1fr;
  gap: 3rem;
  padding: $mg-7 1rem;

  h3 {
    margin-bottom: $mg-3;
    &::after {
      content: "";
      display: block;
      width: 75px;
      height: 3px;
      background-color: $color-1;
    }
  }
}

@include breakpoint(1200) {
  .apropos__first .container,
  .apropos__second,
  .apropos__securite {
    display: block;
    img {
      display: none;
    }
  }
}
