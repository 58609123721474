.header__menu {
  .menu__main {
    ul {
      display: flex;
      align-items: center;
    }
  }
  //------------------------------------------------------ li
  .menu__item {
    margin-left: rem(40);
  }

  //------------------------------------------------------ a
  .menu__link {
    font-size: rem(20);
    // font-weight: 500;
    text-transform: uppercase;
    transition: all 200ms;
  }

  .menu__link--active {
    color: $color-1;
    &.menu__link--contact {
      color: white;
    }
  }

  //------------------------------------------------------ phone

  .menu__phone {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    span {
      display: block;
      line-height: 1;
    }
  }

  .phone__title {
    color: $gray-6;
    text-transform: uppercase;
    font-size: rem(16);
  }

  .phone__number {
    font-size: rem(30);
    font-weight: 500;
  }

  //------------------------------------------------------ hover
  .menu__link:hover {
    color: $color-2;
  }

  //------------------------------------------------------ bouton menu
  #menu__btn {
    display: none;
    position: relative;
    cursor: pointer;
    z-index: 9999;
    outline: 0;
    background-color: transparent;
    border: 0;
    &::after {
      content: "menu";
      display: inline-flex;
      text-transform: uppercase;
      color: $color-1;
      line-height: 0;
      font-family: $font-normal;
    }
  }

  //------------------------------------------------------ BARS

  .menu__bar {
    position: relative;
    display: block;
    width: 40px;
    height: 3px;
    background-color: $color-1;
    margin-bottom: 7px;
    border-radius: 2px;
    transition: all 150ms;
  }

  #menu__btn.menu__btn--active {
    position: relative;
    .menu__bar--1 {
      opacity: 0;
    }
    .menu__bar--2 {
      transform: rotate(45deg);
    }
    .menu__bar--3 {
      transform: rotate(-45deg);
      top: -10px;
    }
  }
}

//------------------------------------------------------ RESPONSIVE
@include breakpoint(1600) {
  .header__menu {
    //------------------------------------------------------ li
    .menu__item {
      margin-left: rem(40);
    }

    //------------------------------------------------------ a
    .menu__link {
      font-size: rem(16);
    }

    //------------------------------------------------------ phone

    .phone__title {
      font-size: rem(16);
    }

    .phone__number {
      font-size: rem(26);
      font-weight: 500;
    }
  }
}

//------------------------------------------------------ RESPONSIVE
@include breakpoint(1240) {
  .header__menu #menu__btn {
    display: block;
  }
  .header__menu .menu__main {
    position: fixed;
    top: 0;
    right: -300px;
    display: flex;
    align-items: center;
    justify-content: start;
    width: 300px;
    height: 100vh;
    background-color: $gray-4;
    transition: all 200ms;
    z-index: -1;
    // -------------------------- ul
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    // -------------------------- li
    .menu__item {
      padding: rem(10) 0;
    }
    .menu__link--contact {
      background-color: transparent;
      border: transparent;
      padding: 0;
      color: $color-dark;
    }
  }
  // -------------------------- menu active
  .header__menu--active .menu__main {
    right: 0;
    z-index: 9998;
  }
}
