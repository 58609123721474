/* ------------------- 15/02/2021 ------------------- */

/* ------------------- BOUTON AJOUTER / MODIF ARTICLE / CATEGORIE ------------------- */

.links_btn_submit {
  margin-top: 1rem;
  input.btn,
  .btn {
    width: auto;
    margin: 0;
    height: initial;
  }
  input.btn {
    min-width: 250px;
    margin-right: 1.5rem;
  }
}

/* ------------------- eo BOUTON AJOUTER / MODIF ARTICLE / CATEGORIE ------------------- */

.espace-admin {
  .content_container {
    display: flex;
    flex-flow: row wrap;

    .menu_admin {
      flex: 0 0 300px;
      max-width: 300px;
      min-width: 300px;
    }

    .item-page {
      flex: 1 0 100%;
      max-width: 100%;
      margin-left: 3rem;
    }
  }

  .admin_space {
    flex: 1;
    margin-left: 2rem;
  }

  #maincontent {
    width: 80rem;
    margin: 3rem auto 5rem auto;
  }
}

/* --------- SMALL HEADER --------- */
.small_admin_header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;

  .admin_search {
    max-width: 400px;
    margin-left: 1rem;
    border-radius: 50px;
    height: 44px;
  }
  .btn_add_admin {
    margin: 0;
  }
}

/* ------------------------------------------------------------- RESPONSIVE ------------------------------------------------------------- */

@media screen and (max-width: 1400px) {
  .espace-admin {
    #maincontent {
      width: auto;
      margin: 3rem 1rem;
    }
  }
}

@media screen and (max-width: 1000px) {
  .espace-admin {
    .content_container {
      .item-page {
        margin-left: 0;
      }
    }
    .admin_space {
      flex: 1 0 100%;
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 500px) {
  .links_btn_submit {
    input.btn {
      margin-bottom: 1.5rem;
    }
  }
}
