/* ----------- 16/02/2021 ----------- */

.admin_profil {
  form {
    max-width: 400px;

    .input-group {
      svg,
      path {
        fill: $color-1;
      }
    }
  }
}
